.form-control {
  border-radius: 0.1rem;
  outline: initial !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.75rem;
  line-height: 1.5;
  border: 1px solid #464650 !important;
  border-radius: 5px;
  color: #000!important;
  height: initial;
}

.form-control.is-invalid {
  background: #d1d1d1;
}

.login-footer {
  margin-bottom: 10px;
  position: absolute;
  bottom: 0px;
  text-align: center;
  width: 99%;
  font-size: 0.9em;
  color: #868686;
  margin-left: -12px;
}

.login-footer a {
  color: #ef6c00;
}

.action-button {
  background-color: #6c6c6c !important;
}

.action-button:hover {
  background-color: #434343 !important;
}
